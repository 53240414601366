


























































































































































































































































































































































































































































import { Fonction } from "@/api/models/authorization/fonctions/fonction";
import { UserDetail } from "@/api/models/authorization/userDetails/userDetail";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Equipe } from "@/api/models/options/equipes/equipe";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BFormCheckbox,
  BSpinner,
  BFormTextarea,
  BFormInput,
  BFormSelect,
  BAvatar,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@/@core/utils/validations/validations";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { ConsigneCreateModel } from "@/api/models/dossiers/consignes";
import { Dossier, DossierSearchParams } from "@/api/models/dossiers/dossier";
import { Site } from "@/api/models/options/entreprises/site";
import store from "@/store/index";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";

@Component({
  components: {
    RessifnetDateInput,
    RessifnetQuillEditor,
    BFormRadioGroup,
    BAvatar,
    BFormRadio,
    BFormGroup,
    BSpinner,
    BFormTextarea,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    SearchableVueSelect,
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
  },
})
export default class CreationConsigne extends Vue {
  required = required;
  refreshIndex = 1;

  dateToday = new Date().toLocaleString("fr-FR", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  searchDossiersParams: DossierSearchParams = {
    entrepriseId: undefined,
    sitesId: undefined,
    nomPersonne: undefined,
  };

  fonctions: Fonction[] = [];
  loading = true;

  typeConsigneSelected = null;
  typeConsigneOptions = [
    { text: "dossier de salarié", value: "dossier" },
    { text: "entreprise", value: "entreprise" },
  ];

  searchEntreprisesTotalCount = 0;
  searchEntreprisesItems: Entreprise[] = [];

  searchSitesTotalCount = 0;
  searchSitesItems: Site[] = [];

  pageSize = 10;
  pageNumber = 1;

  displayNouvelleDemande: boolean = false;

  selectedIds: any = {};
  model: ConsigneCreateModel = {
    dateDebut: this.dateToday,
    dateEcheance: this.dateToday,
    importance: undefined,
    message: undefined,
    destinatairesEquipes: [],
    destinatairesUtilisateurs: [],
  };

  equipeSelected: boolean = false;
  selectedDossier: Dossier | null = null;
  redacteurSelected: boolean = true;
  importanceWasSelected: boolean = false;
  listEquipes: Equipe[] = [];
  listEquipesTotalCount = 0;
  listMembres: UserDetail[] = [];
  listMembresTotalCount = 0;

  perfectScrollbarSettings = {
    maxScrollbarLength: 100,
  };
  currentSelectedNom: any = null;
  currentSelectedNumero: any = null;
  showAutocompleteNom = false;
  showAutocompleteNumero = false;
  timeout: any = null;
  autocompleteNomLoading = false;
  autocompleteNumeroLoading = false;

  searchResult: PaginatedList<any> | null = null;
  searchLoading = false;
  searchResultAutocompleteNom: any[] = [];
  searchResultAutocompleteNumero: any[] = [];
  searchAutocompleteNomTotalCount = 0;
  searchAutocompleteNumeroTotalCount = 0;

  async created() {
    await Promise.all([]).then(() => (this.loading = false));
  }

  resetModel() {
    this.importanceWasSelected = false;
    this.model = {
      dateEcheance: this.dateToday,
      dateDebut: this.dateToday,
      dossierId: undefined,
      entrepriseId: undefined,
      importance: undefined,
      message: undefined,
      destinatairesUtilisateurs: [],
      destinatairesEquipes: [],
    };
    this.selectedIds = {};
    this.selectedDossier = null;
    this.redacteurSelected = false;
    this.equipeSelected = false;

    this.resetIconsToDefault();

    (this.$refs['formValidation'] as any).reset();

  }

  @Watch("typeConsigneSelected")
  onTypeConsigneChange(typeConsigne: string) {
    if (typeConsigne == "dossier") this.model.entrepriseId = undefined;
    else if (typeConsigne == "entreprise") {
      this.searchDossiersParams = {
        entrepriseId: undefined,
        sitesId: undefined,
        nomPersonne: undefined,
      };
      this.currentSelectedNom = null;
      this.currentSelectedNumero = null;
      this.searchResult = null;
      this.searchAutocompleteNomTotalCount = 0;
      this.searchAutocompleteNumeroTotalCount = 0;
      this.searchResultAutocompleteNom = [];
      this.searchResultAutocompleteNumero = [];
      this.selectedDossier = null;
      this.model.dossierId = undefined;
    }
  }

  resetIconsToDefault() {
    var icons = document.querySelectorAll(".icon-status-item");
    icons.forEach((icon) => {
      icon.classList.remove("active");
    });
  }

  async save() {
    let data: ConsigneCreateModel = {
      message: this.model.message,
      dossierId: this.model.dossierId,
      entrepriseId: this.model.entrepriseId,
      dateDebut: this.model.dateDebut,
      dateEcheance: this.model.dateEcheance,
      importance: this.model.importance,
      nouvelleDemande: this.model.nouvelleDemande ?? false,
      destinatairesEquipes: this.model.destinatairesEquipes,
      destinatairesUtilisateurs: this.model.destinatairesUtilisateurs,
      redacteurDestinataire: this.redacteurSelected ?? false,
    };
    this.typeConsigneSelected == "entreprise"
      ? await this.$http.ressifnet.consignes
          .createForEntreprise(this.model.entrepriseId, data)
          .then(
            async (response: any) => {
              successAlert.fire({
                title: "Création d'une consigne sur l'entreprise",
                text: "Consigne ajoutée avec succès",
              });

              this.resetModel();
             
            },
            (error: any) => {
              if (
                error?.response?.data?.errors &&
                Object.keys(error.response.data.errors).length
              ) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                errorAlert.fire({
                  text: error?.response?.data?.detail ?? error.message,
                });
              }
            },
          )
      : await this.$http.ressifnet.consignes
          .createForDossier(this.model.dossierId, data)
          .then(
            async (response: any) => {
              successAlert.fire({
                title: "Création d'une consigne sur le dossier",
                text: "Consigne ajoutée avec succès",
              });

              this.resetModel();
              
            },
            (error: any) => {
              if (
                error?.response?.data?.errors &&
                Object.keys(error.response.data.errors).length
              ) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                errorAlert.fire({
                  text: error?.response?.data?.detail ?? error.message,
                });
              }
            },
          );

          this.$emit("reload-list");
          if (this.$store?.state?.user?.user?.uid) {
                this.$store.dispatch('consignes/fetchConsigne');
          };
  }

  async searchEquipes(params?: any) {
    if (!params || params?.reset) {
      this.listEquipes =
        this.listEquipes!.filter((e: any) =>
          this.model.destinatairesEquipes?.includes(e.id),
        ) ?? [];
    }

    await this.$http.ressifnet.consignes
      .paginatedListEquipes(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
      )
      .then(
        (response: PaginatedList<Equipe>) => {
          this.listEquipes = this.listEquipes!.concat(
            response.items.filter(
              (ri) => !this.listEquipes!.some((lsi: any) => lsi.id == ri.id),
            ),
          );
          this.listEquipesTotalCount = response.totalCount;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        },
      );
  }

  async searchMembres(params?: any) {
    if (!params || params?.reset) {
      this.listMembres =
        this.listMembres!.filter((e: any) =>
          this.model.destinatairesUtilisateurs?.includes(e.id),
        ) ?? [];
    }

    await this.$http.ressifnet.consignes
      .paginatedListMembres(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
      )
      .then(
        (response: PaginatedList<UserDetail>) => {
          this.listMembres = this.listMembres!.concat(
            response.items.filter(
              (ri) => !this.listMembres!.some((lsi: any) => lsi.id == ri.id),
            ),
          );
          this.listMembresTotalCount = response.totalCount;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        },
      );
  }

  async loadEquipes() {
    if (this.equipeSelected) {
      this.model.destinatairesEquipes = undefined;
      await this.searchEquipes(null);
    } else {
      this.model.destinatairesEquipes = undefined;
      this.listEquipes = [];
    }
  }

  async loadMembres() {
    if (this.selectedIds) {
      this.model.destinatairesUtilisateurs = undefined;
      await this.searchMembres(null);
    } else {
      this.model.destinatairesUtilisateurs = undefined;
      this.listMembres = [];
    }
  }

  onSelectedImportance(value: any, element: any, model: any) {
    // Element avec déjà une valeur -> ToSave
    if (
      element.target.closest(".icon-status-item").classList.contains("active")
    ) {
      this.importanceWasSelected = true;
      element.target.closest(".icon-status-item").classList.remove("active");
      model.importance = null;
    }
    // Element sans valeur
    else {
      var parent = element.target.closest(".icon-list-custom");
      var icons = parent.querySelectorAll(".icon-status-item");
      icons.forEach((icon: any) => {
        icon.classList.remove("active");
      });
      element.target.closest(".icon-status-item").classList.toggle("active");

      model.importance = value;
    }
    this.refreshIndex++;
  }

  async onEntrepriseSelected(val: any) {
    if (val) {
      this.searchDossiersParams.sitesId = [];
      this.$set(this.searchDossiersParams, "sitesId", []);
      this.searchSitesItems = [];
      await this.searchSites({});
      await this.searchDossiers({});
    }
  }

  async onSiteSelected(val: any) {
    if (val) {
      this.searchDossiersParams.nomPersonne = "";
      this.$set(this.searchDossiersParams, "nomPersonne", "");
    }
  }

  calculerAge(birthday: any) {
    var today = moment();
    var birthdate = moment(birthday, "DD-MM-YYYY");
    const age = today.diff(birthdate, "years");
    return age;
  }

  async searchDossiers(params: any) {
    store.state.searchDossier = this.searchDossiersParams;
    this.searchLoading = true;
    await this.$http.ressifnet.dossiers
      .search({
        ...this.searchDossiersParams,
        pageNumber: params.pageIndex,
        pageSize: this.pageSize,
        orderBy: params.sortBy,
        orderByDescending: params.isSortDirDesc,
      })
      .then((response: PaginatedList<any>) => {
        this.searchResult = response;
        this.searchLoading = false;

        setTimeout(() => {
          let formCard = this.$refs["searchDossierTable"] as any;
          if (formCard != null) formCard.scrollIntoView({ behavior: "smooth" });
        }, 350);
      });
  }

  @Watch("searchDossiersParams.nomPersonne", { immediate: false })
  async searchDossiersAutocompleteNom(params: any) {
    if (
      (!this.searchDossiersParams.nomPersonne &&
        !this.searchDossiersParams.numeroDossier) ||
      (this.searchDossiersParams.nomPersonne &&
        this.searchDossiersParams.nomPersonne.length < 2)
    )
      return;

    this.searchResultAutocompleteNumero = [];
    this.searchAutocompleteNumeroTotalCount = 0;

    this.showAutocompleteNom = true;
    this.autocompleteNomLoading = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.$http.ressifnet.dossiers
        .search({
          nomPersonne: this.searchDossiersParams.nomPersonne,
          entrepriseId: this.searchDossiersParams?.entrepriseId ?? "",
          sitesId: this.searchDossiersParams?.sitesId ?? "",
          pageNumber: 1,
          pageSize: 10,
          withoutCount: true,
        })
        .then((response: any) => {
          this.searchResultAutocompleteNom = response.items;
          this.searchAutocompleteNomTotalCount = response.totalCount;
          this.autocompleteNomLoading = false;
        });
    }, 1500);
  }

  @Watch("searchDossiersParams.numeroDossier")
  async searchDossiersAutocompleteNumero(params: any) {
    this.searchResultAutocompleteNumero = [];
    this.searchAutocompleteNumeroTotalCount = 0;

    if (
      (!this.searchDossiersParams.nomPersonne &&
        !this.searchDossiersParams.numeroDossier) ||
      (this.searchDossiersParams.numeroDossier &&
        this.searchDossiersParams.numeroDossier.length < 3)
    )
      return;

    this.showAutocompleteNumero = true;
    this.autocompleteNumeroLoading = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.$http.ressifnet.dossiers
        .search({
          numeroDossier: this.searchDossiersParams.numeroDossier,
          entrepriseId: this.searchDossiersParams?.entrepriseId ?? "",
          sitesId: this.searchDossiersParams?.sitesId ?? "",
          pageNumber: 1,
          pageSize: 10,
          withoutCount: true,
        })
        .then((response: any) => {
          this.searchResultAutocompleteNumero = response.items;
          this.searchAutocompleteNumeroTotalCount = response.totalCount;
          this.autocompleteNumeroLoading = false;
        });
    }, 1500);
  }

  selectDossier(dossier: any) {
    this.searchDossiersParams.nomPersonne = "";
    this.selectedDossier = dossier;
    this.model.dossierId = dossier.id;
    this.hideAutocomplete();
  }

  hideAutocomplete() {
    this.showAutocompleteNom = false;
    this.showAutocompleteNumero = false;
  }

  async searchEntreprise(params: any) {
    if (!params || params?.reset) {
      this.searchEntreprisesTotalCount = 0;
      this.searchEntreprisesItems = [];
    }

    await this.$http.ressifnet.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
      )
      .then((response: PaginatedList<Entreprise>) => {
        this.searchEntreprisesTotalCount = response.totalCount;
        this.searchEntreprisesItems = this.searchEntreprisesItems!.concat(
          response.items.filter(
            (ri) =>
              !this.searchEntreprisesItems!.some((lsi) => lsi.id == ri.id),
          ),
        );
      });
  }

  async searchSites(params: any) {
    if (!params || params?.reset) {
      this.searchSitesTotalCount = 0;
      this.searchSitesItems = this.searchSitesItems?.filter((e) =>
        this.searchDossiersParams.sitesId?.includes(e.id),
      );
    }

    await this.$http.ressifnet.sites
      .paginatedList(
        this.searchDossiersParams.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        "",
        "",
        "",
        "",
        params.onCreate ? this.searchDossiersParams.siteId : "",
      )
      .then((response: PaginatedList<Site>) => {
        this.searchSitesTotalCount = response.totalCount;
        this.searchSitesItems = this.searchSitesItems!.concat(
          response.items.filter(
            (ri) => !this.searchSitesItems!.some((lsi) => lsi.id == ri.id),
          ),
        );
      });
  }
}
