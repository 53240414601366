







import { Component, Vue } from "vue-property-decorator";
import ListConsignes from './listConsignes/ListConsignes.vue';
import CreationConsigne from "./createConsigne/CreateConsigne.vue";

@Component({
  components: {
    ListConsignes,
    CreationConsigne
  }
})
export default class IndexConsignes extends Vue {
  showCreate = false;

  reloadListConsignes() {
    this.$nextTick(() => {
      if(this.$refs.listConsignes) {
        (this.$refs.listConsignes as ListConsignes).loadConsignes();
      }
    })
  }

  openCreateForm() {
    this.showCreate = true
    this.$nextTick(() => {
      if (this.$refs.createConsigne) {
        (this.$refs.createConsigne as any).$el.focus();

        (this.$refs.createConsigne as any).$el.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
    });
  }
}
