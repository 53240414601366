






















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import { Destinataire } from "@/api/models/dossiers/destinataire";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { TypeConsigne } from "@/api/models/enums/typeConsigne"

import {
  Consigne,
  ConsigneListeFiltres,
} from "@/api/models/dossiers/consignes";
import { BPagination, BFormCheckbox, BForm, BButton } from "bootstrap-vue";

@Component({
  components: {
    BPagination,
    BFormCheckbox,
    BButton,
    BForm,
  },
})
export default class ListConsignes extends Vue {
  listeConsignes: PaginatedList<Consigne> | null = null;

  paginatedList: PaginatedList<Consigne> = new PaginatedList<Consigne>();
  typeConsigne = TypeConsigne;


  filtres: ConsigneListeFiltres = {
    onlyTraitee: false,
    onlyCreatedByMe: false,
    onlyReceived: false,
    onlyDossiers: false,
    onlyEntreprises: false,
    hideAutoConsignes: false,
    order: true,
    currentPage: 1,
    perPage: 15,
  };

  created() {
    this.loadConsignes();
  }

  @Watch("filtres.currentPage")
  async currentPageChange() {
    await this.loadConsignes();
  }

  @Watch("filtres.onlyTraitee")
  async onlyTraite() {
    this.filtres.currentPage = 1;
    await this.loadConsignes();
  }

  @Watch("filtres.onlyReceived")
  async onlyReceivedFunction() {
    await this.loadConsignes();
  }

  @Watch("filtres.onlyCreatedByMe")
  async onlyCreatedByMeFunction() {
    await this.loadConsignes();
  }

  @Watch("filtres.order")
  async OrdreChange() {
    await this.loadConsignes();
  }

  @Watch("filtres.typeConsigne")
  async byTypeConsigne(){
       await this.loadConsignes();
  }

  @Watch("filtres.onlyDossiers")
  async onlyDossiersFunction() {
    await this.loadConsignes();
  }

  @Watch("filtres.onlyEntreprises")
  async onlyEntreprisesFunction() {
    await this.loadConsignes();
  }

  async loadConsignes() {
    await this.$http.ressifnet.destinataires
      .get(this.$store.state.user.user.uid, this.filtres)
      .then(
        (response: PaginatedList<Consigne>) => {
          this.listeConsignes = response;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        },
      );
  }

  goDetails(consigneId: string) {
    this.$router.push({
      name: "consignes-details",
      params: { id: consigneId },
    });
  }

  getClassImportance(importance: number) {
    switch (importance) {
      case 1:
        return "bg-light-success";
      case 2:
        return "bg-light-warning";
      case 3:
        return "bg-light-danger";
    }
  }
}
